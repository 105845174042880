<template>
  <!-- @vue-ignore -->
  <textarea :id="editorId" v-model="text"></textarea>
</template>

<script setup lang="ts">
// @ts-nocheck
import { Cookies } from 'quasar'
import { useI18n } from 'vue-i18n'

export interface CustomMenu {
  menuId: string
  executeType: string
  imageUrl: string
  name: string
  event: any
}

export interface Props {
  id: string
  height?: string
  width?: string
  clipboardPostUrl?: string
  inlinePostUrl?: string
  parentContainer: any
  modelValue?: string | null
  addMenu: CustomMenu
  showTab: boolean
  placeholder: string
}

const props = withDefaults(defineProps<Props>(), {
  id: '',
  height: '400px',
  width: '100%',
  clipboardPostUrl: '',
  inlinePostUrl: '',
  parentContainer: undefined,
  modelValue: undefined,
  showTab: true,
  placeholder: '',
  addMenu: null
})

const baseUrl = `${window.location.protocol}//${window.location.host}${import.meta.env.V_RESOURCE_BASE_PATH}`

const editorId = 'namose-' + props.id
const editorBaseUrl = `${baseUrl}/lib/crosseditor/`
let crossEditor = {}

// 에디터를 로드하지 않았을때 사용하는 변수
const text = ref('')

const langCode = Cookies.get('GWP_LANGUAGE_CODE') ?? 'ko'
const { t } = useI18n()

// https://comp.namoeditor.co.kr/ce4/help/ko/dev_index.html
// 다국어 추가 시 10프로 비용 추가
let crossEditorLangCode = 'kor'
if (langCode == 'en' || langCode == 'zh') {
  crossEditorLangCode = 'enu'
}

const create = () => {
  const CE = (crossEditor = new window['NamoSE'](editorId))

  const params = {
    Width: props.width,
    Height: props.height,
    DefaultFont: 'Malgun Gothic',
    DefaultFontSize: '11pt',
    LineHeight: '180%',
    AttrImage: true,
    Placeholder: t('ContentsHere'),
    UseToolbar: true,
    CreateToolbar:
      'iconmenu|spacebar|newdoc|fopen|saveas|autosave|print|pagebreak|spacebar|undo|redo|cut|copy|paste|pastetext|insertcode|search|replace|selectall|spacebar|image|ce_imageeditor|backgroundimage|insertchart|insertfile|spacebar|hyperlink|remove_hyperlink|bookmark|inserthorizontalrule|inserthorizontalruletype|specialchars|emoticon|enter|tableinsert|tabledraginsert|spacebar|tablerowinsert|tablerowdelete|tablecolumninsert|tablecolumndelete|spacebar|tablecellmerge|tablecellsplit|spacebar|tablecellattribute|tablecellbgcolor|tableborder_group|spacebar|word_layer|spacebar|layout|spacebar|word_dir|spacebar|privacy|validation|spacebar|translator|dict|spacebar|spellchecker|enter|word_style|spacebar|word_color|cancelattribute|spacebar|word_script|spacebar|formatcopy|spacebar|word_justify|word_indentset|txtmargin|paragraphsetup|spacebar|word_listset|spacebar|blockquote|blockquotetype|templatelist|insertdatetime|insertdatetimelist|enter|formatblock|fontname|fontsize|lineheight|spacebar|showruler|fixedwidth|fullscreen|responsive|tagcleaner|topdf|setup|help|information|word',
    Placeholder: props.placeholder ? props.placeholder : t('ContentsHere'),

    UploadFileExecutePath: props.inlinePostUrl,
    PluginModeExecutePath: props.clipboardPostUrl,

    UserLang: crossEditorLangCode,
    IconColor: 'default',
    FullScreen: false,
    ParentEditor: props.parentContainer,
    CustomFieldElementID: editorId,
    EditorBaseURL: editorBaseUrl,
    event: {
      OnInitCompleted: () => {
        update()
      },
      // Key를 하나하나 받아오는 경우
      CE_OnKeyActive: (e) => {
        if (e.type == 'keyup') {
          emit('update:modelValue', crossEditor.GetBodyValue())
        }
      },
      CE_OnCustomMenu: (e) => {
        if (props.addMenu != null) {
          //alert('customMenuID:' + e.customMenuID + '\n' + 'type:' + e.type)
          props.addMenu.event(e)
        }
      }
    },
    Css: ''
  } as any

  if (props.addMenu != null) {
    params.AddMenu = `${props.addMenu.menuId},${props.addMenu.executeType},${props.addMenu.imageUrl},${props.addMenu.name}`
    params.CreateToolbar = params.CreateToolbar + `|${props.addMenu.menuId}`
  }

  CE.params = params
  CE.EditorStart({ ContextName: 'PortalRest' })
}

watch(
  () => props.parentContainer,
  (newValue, oldValue) => {
    if (!window['NamoSE']) {
      const editorLoader = document.createElement('script')
      editorLoader.src = editorBaseUrl + 'js/namo_scripteditor.js'
      document.body.appendChild(editorLoader)
      editorLoader.addEventListener('load', () => {
        create()
      })
    } else {
      create()
    }
  }
)

watch(
  () => props.showTab,
  (newValue, oldValue) => {
    if (newValue) {
      showBottomTab()
    } else {
      hideBottomTab()
    }
  }
)

/** 웹에디터 화면 하단의 html 탭 표시 */
const showBottomTab = () => {
  crossEditor.ShowTab(true)
  // NOTE : 탭 표시 상태 변경 시에 화면에 바로 반영되지 않는 현상이 있어서 ResetEditorHeight 호출해줌
  crossEditor.ResetEditorHeight()
}

/** 웹에디터 화면 하단의 html 탭 숨김 */
const hideBottomTab = () => {
  crossEditor.ShowTab(false)
  // NOTE : 탭 표시 상태 변경 시에 화면에 바로 반영되지 않는 현상이 있어서 ResetEditorHeight 호출해줌
  crossEditor.ResetEditorHeight()
}

const update = (val?: string) => {
  // HTML탭을 사용할지 여부
  crossEditor.ShowTab(props.showTab)

  val = (val || props.modelValue) as string
  try {
    if (val) crossEditor.SetBodyValue(val)
  } catch (e) {
    console.log(e)
    text.value = val
  }
}

const getValue = (xss: boolean) => {
  try {
    let value = crossEditor.GetBodyValue()

    if (xss) value = value.replace(/<\/?[^>]+(>|$)/g, '')

    return value
  } catch (e) {
    console.log(e)
    return text.value
  }
}

const isEmpty = (): boolean => {
  try {
    // HTML탭에서 수정한 내역이 TextValue값에 실시간으로 반영되지 않아서 사이드이펙트를 고려하여 이곳에 현행화..
    const htmlValue = crossEditor.GetBodyValue() as string
    emit('update:modelValue', htmlValue)

    crossEditor.SetBodyValue(htmlValue)
    const value = crossEditor.GetTextValue() as string

    if (value != null && value.trim() != '') {
      return false
    }

    // 이미지가 있는 경우 패스
    if (htmlValue.includes('img')) {
      return false
    }

    return true
  } catch (e) {
    console.log(e)
    return true
  }
}

// 0  : 문서 맨 앞
// -1 : 문서 맨 뒤
// 1  : 현재 커서 위치
// 기본값 1
const focus = (cursorType: 0 | -1 | 1 = 1) => {
  crossEditor.SetFocusEditor(cursorType)
}

const setBodyValue = (value: string) => {
  crossEditor.SetBodyValue(value)
  const htmlValue = crossEditor.GetBodyValue() as string
  emit('update:modelValue', htmlValue)
}

defineExpose({
  isEmpty,
  getValue,
  update,
  focus,
  setBodyValue
})

onUnmounted(async (): Promise<void> => {
  try {
    // @ts-ignore
    crossEditor.destroyEditor()
  } catch (e) {
    console.log(e)
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', text: string): void
}>()
</script>
